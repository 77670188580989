import styled from "styled-components"
import fieldPic from "../../images/mountain-city.jpg"

const BackgroundImageStyles = styled.div`
  position: absolute;
  z-index: -1;
  background: url('${fieldPic}') center center no-repeat;
  background-size: cover;
  filter: blur(3px);
  height: 100vh;
  width: 100vw;
`

export default BackgroundImageStyles